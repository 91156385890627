import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./Home.css";
import { NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import FAQ from "../components/FAQ";
function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="page-content">
        <div className="desktop-banner wrapper gradient-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="home-text-wrapper">
                  <div className="home-text">
                    <h1 className="text-hover">
                      Welcome to <br />
                      Abhath Analytics
                    </h1>
                    <p className="text-hover">
                      Data Solutions and Technology for Market Researchers
                    </p>
                    <button className="btn btn-sm">READ MORE</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img pt-3 ">
                  <img
                    className="home-img d-block w-100"
                    src="images/home/1.png"
                    alt="home main banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" mobile-banner position-relative ">
          <div className="img img-hover">
            <img
              className="home-img d-block w-100"
              src="images/home/home-online-surveys3.png"
              alt="home main banner"
            />
            <div className="text-box">
              <div className="text">
                <h1 className="text-hover">
                  Welcome to <br />
                  Abhath Analytics
                </h1>
                <p className="text-hover">
                  Data Solutions and Technology for Market Researchers
                </p>
                <button className="btn btn-sm">READ MORE</button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg1">
          <div className="home-about-us py-5">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-md-6 pe-md-4">
                  <div className="content-wrapper">
                    <h2 className="h3 heading-color">
                      {" "}
                      WELCOME TO ABATH ANALYTICS
                    </h2>
                    <p>
                      We are global data collection and market research
                      solutions provider. Abath Analytics provides extensive
                      end-to-end data solutions and technology for market
                      research. We are always focused to meet client’s
                      expectations and can tackle with tighter deadlines with
                      hard to reach audiences along with representative sampling
                      for better research insights. Our market research
                      solutions enable better results for clients and help their
                      business grow. We offer services in over 40 countries and
                      multiple languages. You can trust your multi-country study
                      to Abath Analytics because we have successfully completed
                      over 25% projects across multiple countries.
                    </p>

                    <div className="button">
                      <NavLink to="/about-us">
                        <span>Read More</span>
                        <span className="icon flex align-items-center">
                          {" "}
                          <FaArrowRight />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ps-md-4">
                  <div className="vid-wrapper">
                    <video className="w-100" muted loop="true" autoPlay="true">
                      <source
                        src="images/home/abhath-video.m4v"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg2">
          <div className="home-about py-5">
            <div className="row mx-0">
              <div className="col-lg-8">
                <div className="image-wrapper">
                  <div className="img img-shadow">
                    <img src="images/home/about.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about-text-wrapper">
                  <div className="about-text">
                    <p className="">WHAT WE DO</p>
                    <h2 className="h3 heading-color">
                      DATA SOLUTIONS AND TECHNOLOGY FOR MARKET RESEARCHERS
                    </h2>
                    <p>
                      The importance of data solutions and technology is found
                      to be highly indispensable to organizations in
                      spearheading their business operations. Ipso facto, among
                      the foremost companies in the world, Abhath Analytics has
                      an established and recognized pedigree when it comes to
                      digital research data analytics. Our efforts are directed
                      towards acquiring better insights and{" "}
                    </p>

                    <div className="button">
                      <NavLink to="/online-survey">
                        <span>Get to Know us</span>
                        <span className="icon flex align-items-center">
                          {" "}
                          <FaArrowRight />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-wrapper pb-5">
          <h2 className="h1 fw-bold heading-color text-center py-4">
            Frequently Asked Questions
          </h2>

          <div className="container">
            <FAQ />
          </div>
        </div>

        <div className="form-wrapper">
          <div className="form-content">
            <div className="container">
              <div className="row">
                <div className="col-12 mx-auto mb-5 p-0">
                  <h2 className="text-center text-white">
                    Update & Newsletters
                  </h2>
                </div>
              </div>
              <form>
                <div className="row text-center"></div>
                <div className="form-row form1 row">
                  <div className="form-group col-sm-3 mb-3">
                    <label for="" class="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      required
                    />
                  </div>
                  <div className="form-group col-sm-3 mb-3">
                    <label for="" class="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      required
                    />
                  </div>
                  <div className="form-group col-sm-3 mb-3">
                    <label for="" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form-group col-sm-3 mb-3">
                    <label for="" class="form-label">
                      Region
                    </label>
                    <select
                      class="form-select"
                      id="region"
                      name="region"
                      required
                    >
                      <option value=""></option>
                      <option value="REGION">REGION</option>
                      <option value="USA">USA</option>
                      <option value="India">India</option>
                      <option value="China">China</option>
                    </select>
                  </div>

                  <button className="btn  form-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
