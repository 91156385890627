import React, { useState } from "react";

import { NavLink  } from "react-router-dom";
import { FaBars, FaCaretDown } from "react-icons/fa";

function Header() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [drop1, setDrop1] = useState(false);
 


const toggleDrop1 =() =>{
setDrop1(!drop1)

}


 
  return (
    <div>
      <header>
        <div className="header">
         

          
          <nav className="container">
            <div className="logo  ">
              <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/">
                <img src="images/abhath.png" alt=" logo" />
              </NavLink>
            </div>

            <div className={showMediaIcons ? 'menu mobile-menu-link' : ' menu'}>
              <ul className="main-ul">

              <li>
                  <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/">Home</NavLink>
                </li>
                <li className="position-relative" onClick={toggleDrop1}>
                  <NavLink>Data Collection </NavLink>
                  <span className="switch-toggle tc1">
                 
                  <i>
                  <FaCaretDown />
                </i>
                    
                  </span>
                  <div className={drop1 ? 'drop openDrop': 'drop'}>
                   
                    <ul>
                      <li>
                        <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/online-survey">Online Survey</NavLink>
                      </li>
                       <li>
                        <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/Cati">CATI</NavLink>
                      </li>

                       <li>
                        <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/survey-programming">
                          Survey Programming & Hosting
                        </NavLink>
                      </li>
                    </ul>
                  
                  </div>
                </li>
               
                <li>
                <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="about-us">About Us</NavLink>
              </li>
                <li>
                  <NavLink onClick={() => setShowMediaIcons(!showMediaIcons)}  to="/Contact">Contact</NavLink>
                </li>
               
              </ul>
            </div>
            <div className="hamburger-menu">
              <NavLink  onClick={() => setShowMediaIcons(!showMediaIcons)}>
                <FaBars />
              </NavLink>
            </div>
          </nav>
        </div>
        
      </header>
    </div>
  );
}
export default Header;
