import React ,{useEffect} from "react";
import { useLocation } from 'react-router-dom'

import Banner from "../components/Banner";

function Cati() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className="page-content">
      <Banner image="images/service2/main.webp" name="CATI" />

      <div className="bg2">
        <div className="container">
          <div className="row py-5 " >
            <div className="col-lg-6 pe-lg-4">
              <h2 className=" heading-color py-3 ">CATI</h2>
              <p className="    text-justify">
              For tasks that require customer support or expert guidance, Abhath Analytics CATI is the ideal platform for all your queries. Our CATI research professionals at Abhath Analytics can guide you through any kind of CATI research project, regardless of its nature or industry type. Whether you need to connect with B2B or B2C audiences, you can trust Abhath Analytics with all your research needs.
                <p className="  ">
            
                  <strong>At Abhath Analytics, we offer:</strong> :
                </p>
                <ul className="point_list">
                  <li className="  mb-2">
                  A professional interviewing team with over 40 stations
                  </li>
                  <li className="  mb-2">
                  Interviewers who are experts in subjects such as IT, business, management, and more
                  </li>
                  <li className="  mb-2">
                  A team of professionals who monitor and analyze all interviews to ensure high-quality results.
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="side-img pt-3">
                <img
                  className=" rounded shadow"
                  src="images/service2/cati.webp"
                  alt="cati"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg1">
        <div className="container">
          <div className="row py-5 d-flex flex-wrap-reverse" >
            <div className="col-lg-6 pe-lg-4">
              <div className="side-img pt-3">
                <img
                  className=" rounded shadow"
                  src="images/service2/b2c-cati.webp"
                  alt="call2"
                />
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <span id="catiB2C">
                <h2 className=" heading-color  ">B2C (CATI)</h2>
                <p className="   ">
                  Experienced and Professional CATI Team Gives Most Accurate &
                  Reliable Data
                </p>
                <p className="    text-justify">
                At Abhath Analytics, our team of CATI experts delivers the most accurate and reliable data. With their professional tone and expert interviewing skills, our interviewers engage audiences and guests to make surveys more interesting and engaging.
                </p>
                <p className="  ">
                  <strong>
                  Why choose Abhath Analytics as your CATI partner? We offer:
                  </strong>
                </p>
                <ul className="point_list ">
                  <li className="  mb-2 ">
                  Data collected from authenticated and verified sources for the highest level of accuracy
                  </li>
                  <li className="  mb-2">
                  Cost-effective methods that save you time and money.
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg2">
        <div className=" container ">
          <div className="row py-5">
            <div className="col-lg-6 pe-lg-4">
              <h2 className=" heading-color py-3 "> B2B (CATI)</h2>
              <p className="   ">
                Enjoy Steadfast B2B Interviewers With C-Level Interviewing
                Specialists
              </p>
              <p className="    text-justify">
              Abhath Analytics offers steadfast B2B interviewers with C-level interviewing specialists. We understand that high-quality results are crucial for business success, which is why we have a team of qualified CATI professionals dedicated to conducting B2B interviews.
              </p>
              <p className=" ">
                <strong>Our CATI team:</strong>{" "}
              </p>
              <ul className="point_list ">
                <li className="  mb-2">
                Has over 2 years of experience in conducting B2B interviews
                </li>
                <li className=" ">
                Holds master's degrees in their respective fields for expertise and insights that are relevant and up-to-date.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="side-img pt-3 ">
              
                <img
                  className=" rounded shadow"
                  src="images/service2/b2b-cati.webp"
                  alt="b2b"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cati;
