import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import './Header-footer.css'
import './slick.css';
import './slick-theme.css'
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Online from "./pages/Online-survey";
import Cati from "./pages/Cati";
import Survey from "./pages/Survey-programming";
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/survey-programming" element={<Survey />} />
        <Route path="/Cati" element={<Cati />} />
        <Route path="/online-survey" element={<Online />} />
        <Route path="about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
       
      </Routes>
     <Footer />
    </Router>
  );
}

export default App;
