import React ,{useEffect} from "react";
import { useLocation } from 'react-router-dom'
import Banner from "../components/Banner";

function About() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className="page-content">
      <Banner image="images/about-us/about-us.jpg" name="About Us" />
<div className="bg2">


      <div className=" container py-5">
        <div className="row d-flex  align-items-center">
          <div className="col-lg-12 ">
            <p className="">
              {" "}
              Abhath Analytics is a global research platform that offers an
              extensive range of high-end data solutions to companies. We focus
              on meeting all your clients’ expectations by providing them with
              technology-driven data solutions. The data we provide helps
              companies tackle increasing competition and meet deadlines. In the
              current world, it is hard to reach audiences and take their
              valuable feedback. However, at Abhath Insights, our market
              research solutions can take your business ahead. We offer our
              services in 40 different countries and their native language. We
              aim at making this world a better place for companies that want to
              give the best to their customers. With our high-end data
              solutions, you can trust us to get multi-country studies related
              to products and services. Over the years, we have completed over
              25% of projects across multiple countries, thereby satisfying our
              clients with our services.{" "}
            </p>
            <p className="">
              {" "}
              We are overwhelmed with the feedback received from our clients.
              Abhath Analytics is constantly working on innovative technologies
              and tool that allows us to provide cost-effective and useful data
              solutions to all our clients.
            </p>
          </div>
        </div>
      </div>
   </div>   
   <div className="bg1">
   
 
      <div className="container py-5">
        <div className="row d-flex flex-wrap-reverse about" id="why-us">
          <div className="col-md-5 pe-lg-4">
            <div className="pt-3">
              <img
                className=" rounded shadow"
                src="images/about-us/why-choose-us.jpg"
                alt="why-us"
              />
            </div>
          </div>
          <div className="col-md-7 ps-lg-4">
            <h2 className="heading-color"> Why you choose us?</h2>
            <p className="    text-justify">
              Success comes to those who move ahead with innovation and grasp
              all kinds of opportunities the industry presents to them.
              Opportunity and growth are two pillars that lead a company to its
              pinnacle of success. By uncovering the most accurate insights and
              making the best use out of them drives smart and effective
              decision-making that ensures the smooth running of a business
              empire.
            </p>
            <p className="    text-justify">
              The insights a company relies on must be accurate and should come
              from real people who want their voices to be heard. These people
              want to share their opinions with others thereby making this world
              a better place to live. Abhath Analytics works on the motive of
              providing unparalleled and quality-rich data that is connected to
              different kinds of sources.
            </p>
            <p className="    text-justify">
              The data we collect is by using the most advanced and innovative
              technology that brings out answers to all your questions. With
              more than 3 years of experience in this field, we excel in coming
              up with insights and data collected from leaders who want to
              influence the world with their opinions. Abhath Analytics is a
              trusted partner of yours that will provide you with the most
              authenticated and accurate data that can grow your firm.
            </p>
            <p className="    text-justify">
              With millions of data collected from people all over the world,
              Abhath Analytics can give you an insight into what people want or
              desire from a company in terms of products or services. We value
              our clients and that is our motivating factor.
            </p>
          </div>
        </div>
      </div>
       </div> 
    </div>
  );
}

export default About;
