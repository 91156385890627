import React ,{useEffect} from "react";
import { useLocation } from 'react-router-dom'
import Banner from '../components/Banner'

function Contact() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
    <div className="page-content"> 
    <Banner image='images/bg-contactus.jpg' name='Contact'/>

    <div className="form-wrapper">
    <div className="contact-form-content">
      <div className="container">
        <form>
          <div className="row text-center"></div>
          <div className="form-row form row">
            <div className="col-12 mx-auto mb-5 p-0">
              <h2 className="text-center text-white">Get In Touch</h2>
            </div>
            <div className="form-group col-sm-6 mb-3">
              <label for="" class="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                required
              />
            </div>
            <div className="form-group col-sm-6 mb-3">
              <label for="" class="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                required
              />
            </div>
            <div className="form-group col-sm-6 mb-3">
              <label for="" class="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="form-group col-sm-6 mb-3">
            <label for="" class="form-label">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              required
            />
          </div>
         
            <div className=" col-sm-12">
              <label for="" class="form-label">
              Write a message
              </label>
              <textarea
                className="form-control h-100"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <button className="btn  form-btn gradient-bg">Submit</button>
          </div>
         
        </form>
      </div>
    </div>
  </div>
    
    </div>
    </>
  )
}

export default Contact