import React ,{useEffect} from "react";
import { useLocation } from 'react-router-dom'
import Banner from "../components/Banner";

function Online() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className="page-content">
      <Banner image="images/service1/online-survey.jpg" name="Online Survey" />
<div className="bg1">


      <div className="container  py-5" >
        <div className="row ">
          <div className="col-md-6 pe-lg-4">
            <h2 className="heading-color">Panel</h2>
            <p className="  text-justify  ">
            Abhath boasts a team of diverse and results-driven panelists who are committed to delivering top-notch panels that meet your expectations. Our panels are designed to help researchers obtain faster and better results through data collection. To ensure the highest level of quality, we carefully select our samples from smart and distinguished individuals who are eager to share their insights, thoughts, expertise, and opinions. Our network of participants undergoes multiple filters and pre-validation to ensure top-notch quality. Additionally, our in-house tools enable us to recruit a wide range of diverse individuals and delve deep into each profile. Our profiles include people from various walks of life, from patient-specific or specialty product users to major media market segments.
            </p>
          </div>
          <div className="col-md-6 ps-lg-4">
            <div className=" side-img pt-3">
              <img
                className=" rounded shadow"
                src="images/service1/panel.webp"
                alt="panel"
              />
            </div>
          </div>
        </div>
      </div>
</div>

<div className="bg2">


      <div className="container  py-5" id="B2B/B2C">
        <div className="row  d-flex flex-wrap-reverse">
          <div className="col-md-6 pe-lg-4">
            <div className=" side-img pt-3">
              <img
                className=" rounded shadow"
                src="images/service1/b2b.webp"
                alt="b2b"
              />
            </div>
          </div>
          <div className="col-md-6 ps-lg-4">
            <h2 className="heading-color">B2B</h2>
            <p className="  text-justify  ">
            Abhath analytics have a team of B2B experts who specialize in business research, with two-thirds of our transactional volume coming from this area. We take great care in curating business panels that cater to businesses of all industries and sizes. Our work is trusted and utilized by over 200 research agencies and end clients.
            </p>
            <p className="  text-justify  ">
            Our global business audiences consist of experienced upper management professionals who offer valuable insights into their purchasing decisions, technology usage, and industry roadmaps. We have invested significantly in recruitment to ensure high feasibility and profitability, resulting in an industry-leading average response rate of 20-30%.
            </p>
            <p className="">
           
              <strong>
              Our specialization in Decision Makers (FDMs, GBDMs, HRDMs, ITDMs) allow higher demographic and firmographic targeting including
              </strong>
            </p>
            
          </div>
        </div>
      </div>
</div>

<div className="bg1">



      <div className="container  py-5">
        <div className="row  padding">
          <div className="col-md-6 pe-lg-4">
            <h2 className="heading-color">B2C</h2>
            <p className=" text-justify   ">
            At Abhath, we collect data for thousands of consumer studies every week, but our true expertise lies in the field of brand tracking and awareness surveys. Whether it's product testing or shopping experience, Abhath leads the industry in consumer tracking studies. We have an in-depth understanding of this field, including the ability to distinguish between trending data and low-quality sample strategies that may result in tracking data with significant deviations and spikes in brand awareness and segmentable data.
            </p>
          </div>
          <div className="col-md-6 ps-lg-4">
            <div className=" side-img pt-3 ">
              <img
                className=" rounded shadow"
                src="images/service1/b2c.webp"
                alt="b2c2"
              />
            </div>
          </div>
        </div>
      </div>
</div>

<div className="bg2">

      <div className="container  py-5" id="Health-Care">
        <div className="row  d-flex flex-wrap-reverse">
          <div className="col-md-6 pe-lg-4">
            <div className=" side-img pt-3 ">
              <img
                className=" rounded shadow"
                src="images/service1/healthcare.jpg"
                alt="healthcare"
              />
            </div>
          </div>
          <div className="col-md-6 ps-lg-4">
            <h2 className="heading-color">Healthcare</h2>
            <p className="  text-justify  ">
            Healthcare is a crucial category for Abhath, and we take great care in developing our healthcare panels with genuine insight and attention to detail. We provide researchers with access to patient profiles, which include thousands of documents covering medical conditions and other personal attributes. To ensure the highest quality, we have partnered with top health networks and enlisted the help of experts who identify individuals suffering from specific ailments and conditions. Our networks include traditional partnerships, such as hospitals, patient opt-in programs, and Medicare sources, as well as reputable third-party healthcare affiliate programs that also recruit general patient populations.
            </p>
          </div>
        </div>
      </div>
</div>

     
    </div>
  );
}
export default Online;
