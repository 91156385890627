import React, { Component } from "react";
import Slider from "react-slick";
export default class FAQ extends Component {
  render() {
    const settings = {
      infinite: false,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="question-box flex-center-center">
                <div className="question-img">
                  <img src="images/hotel.jpg" alt="abhath-faq" />
                </div>
                <div className="question">
                  <h2>What is an online survey?</h2>
                </div>
              </div>
              <div class="ans-box flex-center-center">
                <div>
                  <p>
                    An online survey is a research method used to gather
                    information from a targeted audience through the internet.
                    It involves the use of online questionnaires that
                    respondents can complete on their devices, such as
                    smartphones, laptops, or desktop computers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="question-box flex-center-center">
                <div className="question-img">
                  <img src="images/abhath-faq.jpg" alt="abhath-faq" />
                </div>
                <div className="question">
                  <h2>Are online surveys reliable and accurate?</h2>
                </div>
              </div>
              <div class="ans-box flex-center-center">
                <div>
                  <p>
                    Yes, online surveys can be reliable and accurate when
                    designed and executed properly. To ensure the reliability
                    and accuracy of an online survey, it's important to use
                    validated survey questions, target a representative sample,
                    and ensure data quality by checking for incomplete or
                    inconsistent responses.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="question-box flex-center-center">
                <div className="question-img">
                  <img src="images/faq3.jpg" alt="abhath-faq" />
                </div>
                <div className="question">
                  <h2>
                    What are the advantages of conducting an online survey?
                  </h2>
                </div>
              </div>
              <div class="ans-box flex-center-center">
                <div>
                  <p>
                    Online surveys offer several advantages, including
                    cost-effectiveness, speed, convenience, and flexibility.
                    Online surveys can reach a larger audience in a shorter time
                    and offer real-time data analysis.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="question-box flex-center-center">
                <div className="question-img">
                  <img src="images/faq4.jpg" alt="abhath-faq" />
                </div>
                <div className="question">
                  <h2>How do I design an effective online survey?</h2>
                </div>
              </div>
              <div class="ans-box flex-center-center">
                <div>
                  <p>
                    To design an effective online survey, you need to identify
                    the research questions, choose appropriate survey questions,
                    and ensure the survey is easy to understand and navigate.
                    It's also important to test the survey before distributing
                    it to the target audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="question-box flex-center-center">
                <div className="question-img">
                  <img src="images/faq5.jpg" alt="abhath-faq" />
                </div>
                <div className="question">
                  <h2>
                    How can I ensure a high response rate for my online survey?
                  </h2>
                </div>
              </div>
              <div class="ans-box flex-center-center">
                <div>
                  <p>
                    To ensure a high response rate for your online survey, it's
                    essential to keep the survey brief and relevant, clearly
                    communicate the purpose of the survey, offer incentives or
                    rewards, and follow up with reminders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
