import React from 'react'

function Banner(props) {
  return (
    <div>
   
    <div className="banner">
      <div className="main-img img-hover">
        <img src={props.image} alt="contact us" />
        <div className="text">
          <h2>{props.name}</h2>
        </div>
      </div>
    </div>
  
    </div>
  )
}

export default Banner