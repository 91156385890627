import React ,{useEffect} from "react";
import { useLocation } from 'react-router-dom'

import Banner from "../components/Banner";

function Survey() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className="page-content">
      <Banner
        image="images/service3/programming.jfif"
        name="Survey Programming"
      />

      <section className="bg1">
        <div className=" container py-5">
          <div className="row d-flex  flex-wrap-reverse">
            <div className="col-lg-6 pe-lg-4">
              <div className="    pt-4">
                <img
                  className=" rounded shadow"
                  src="images/service3/survey.jpg"
                  alt="survey"
                />
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <h2 className=" heading-color py-2 ">
                Survey Programming and Hosting
              </h2>
              <p className=" text-justify">
                Abhath Analytics offers a highly skilled and experienced team
                for scripting and skilled panel professionals, backed by
                in-house proprietary software and industry-standard tools such
                as Qualtrics, SPSS, and Confirmit. Our surveying program
                provides unmatched perfection in online data collection through
                the use of combinations and algorithms to create the most
                comprehensive questionnaire design for efficient and accurate
                data collection. We also provide specialized monitoring and
                reporting services, with a capable team to translate in various
                languages. Our advanced features include rolling back incomplete
                surveys, completion of surveys at a later date, piping logic and
                conditional logic, pass back linkage, and block rotation. We
                also offer live tracking to ensure the smooth functioning of
                your research project.
              </p>
              <p className="  ">
                <strong>Reporting & Monitoring:</strong>{" "}
              </p>
              <p className="">
                Abhath Analytics also provides specialized monitoring or
                fielding and reporting services and has
              </p>
              <p className=" text-justify">
                Abhath Analytics offers specialized monitoring, fielding, and
                reporting services with a capable team that can translate in
                various languages. Our online survey program includes complex
                features such as rolling back incomplete surveys, completing
                them at a later date, piping and conditional logic, pass back
                linkage to match respondents to your panel, block rotation, and
                live tracking.
              </p>
              <ul className="point_list ">
                <li className="  mb-2">Rolling back if incomplete surveys</li>
                <li className="  mb-2">
                  Completion of incomplete surveys at a later date
                </li>
                <li className="  mb-2">Piping logic and conditional logic</li>
                <li className="  mb-2">
                  {" "}
                  Pass back linkage to allow you to match respondents to your
                  panel
                </li>
                <li className="  mb-2"> Block rotation</li>
             
                  <li> Live tracking</li>
               
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg2">
        <div className=" container py-5">
          <div className="row d-flex d-flex ">
            <div className="col-lg-6 pe-lg-4">
              <h2 className=" heading-color py-2  ">
                Data Processing & Analysis
              </h2>

              <p className="    text-justify ">
                Abhath Analytics conducts in-house data processing and analytics
                to ensure clean, accurate, and secure research data. Our
                dedicated team of data processors delivers customized data that
                meets your specific needs. Our professionals work in multiple
                shifts around the clock to provide high-end solutions to your
                requirements 24/7.
              </p>
              <p className="  ">
                <strong>At Abhath Analytics, we provide:</strong>
              </p>
              <ul className="point_list ">
                <li className="  mb-2"> Accurate and Tailored Data Results </li>
                <li className="  mb-2">Services 24/7</li>
                <li className="  mb-2">
                  
                  On-time delivery of services with the help of more than 20
                  professionals
                </li>

                <li className="  mb-2"> On time delivery</li>
                <li className="  mb-2"> Multiple tools & techniques</li>
                <li className="  mb-2"> 24/7 in house team support</li>
              </ul>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="  pt-4">
                <img
                  className=" rounded shadow"
                  src="images/service3/data-processing.jpg"
                  alt="data-processing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Survey;
